.App {
  text-align: left;
  min-height: 100vh;
  background: linear-gradient(to bottom, rgb(17, 24, 39), rgb(88, 28, 135));
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: white;
  flex-wrap: wrap;
  gap: 15px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-title {
  margin: 0;
  font-size: clamp(24px, 5vw, 36px);
  background: linear-gradient(to right, #c084fc, #db2777);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.Auth-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 5px;
}

.Auth-button {
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  background: transparent;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.Auth-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.Auth-button.active {
  background: linear-gradient(45deg, #c084fc, #db2777);
}

.App-title-link {
  text-decoration: none;
  color: inherit;
}

.Profile {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.profile-photo-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border: 2px solid #007bff;
}

.profile-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-photo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-photo-placeholder input[type="file"] {
  display: none;
}

.profile-photo-placeholder::before {
  content: 'Upload Photo';
  font-size: 14px;
  color: #007bff;
}

.social-links input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

.admin-dashboard {
  background-color: white;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  margin: 20px;
  max-width: 1200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.admin-dashboard h2 {
  color: #333;
  margin-bottom: 20px;
}

.admin-dashboard table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
}

.admin-dashboard th,
.admin-dashboard td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color: #333;
}

.admin-dashboard th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.admin-dashboard tr:hover {
  background-color: #f8f8f8;
}

.tab-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tab-buttons button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background-color: #eee;
  cursor: pointer;
  border-radius: 4px;
  color: #333;
}

.tab-buttons button.active {
  background-color: #007bff;
  color: white;
}

.admin-dashboard button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-dashboard button:hover {
  background-color: #c82333;
}

.error-message {
  background-color: #fff3f3;
  color: #dc3545;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.admin-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.search-box {
  flex: 0 1 250px;
  min-width: 150px;
  margin-left: auto;
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.search-input::placeholder {
  color: #999;
}

/* Add media queries for mobile responsiveness */
@media screen and (max-width: 768px) {
  .App-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
  }

  .Auth-buttons {
    width: 100%;
    justify-content: center;
    gap: 5px;
    padding: 5px;
  }

  .Auth-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* For very small screens */
@media screen and (max-width: 380px) {
  .Auth-buttons {
    flex-direction: column;
    width: 100%;
    padding: 5px;
  }

  .Auth-button {
    width: calc(100% - 10px);
    text-align: center;
  }
}

/* Hide volume control on mobile */
@media screen and (max-width: 768px) {
  .volume-control {
    display: none;
  }
}

/* Ensure auth buttons align properly when volume control is hidden */
@media screen and (max-width: 768px) {
  .Auth-buttons {
    width: 100%;
    justify-content: center;
    gap: 5px;
    padding: 5px;
  }
}