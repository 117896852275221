.admin-dashboard-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.admin-dashboard {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-title {
  text-align: center;
  margin-bottom: 2rem;
}

.admin-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.users-table,
.songs-table,
.comments-table {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
} 