.terms-page {
  min-height: calc(100vh - 80px);
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  color: white;
  background: #121212;
}

.terms-content {
  max-width: 800px;
  width: 100%;
  background: rgba(32, 32, 32, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.terms-content h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #FE6B8B;
}

.terms-content section {
  margin-bottom: 30px;
}

.terms-content h2 {
  color: #FF8E53;
  margin-bottom: 15px;
  font-size: 1.5em;
}

.terms-content p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.terms-content ul {
  margin-left: 20px;
  margin-bottom: 15px;
}

.terms-content li {
  line-height: 1.6;
  margin-bottom: 8px;
}

.terms-button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.back-button {
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  border: 0;
  border-radius: 3px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  color: white;
  padding: 10px 30px;
  cursor: pointer;
  font-size: 16px;
  transition: opacity 0.3s ease;
}

.back-button:hover {
  opacity: 0.9;
} 