.Auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px); /* Adjust based on your header height */
  padding: 20px;
}

.Auth-form {
  background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Auth-form h2 {
  margin-bottom: 20px;
  color: white;
  text-align: center;
}

.Auth-form input,
.Auth-form button {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
}

.Auth-form input {
  background: rgba(255, 255, 255, 0.2);
  color: white;
}

.Auth-form button {
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.Auth-form button:hover {
  background: linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%);
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;  /* Adds space between profile photo and social links */
}

.profile-section h2 {
  margin: 0;
  text-align: center;
}

.profile-photo-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  cursor: pointer;
}

.profile-photo, .profile-photo-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile-photo {
  object-fit: cover;
}

.profile-photo-placeholder {
  background-color: #e0e0e0;
}

.profile-photo-input {
  display: none;
}

.profile-photo-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.profile-photo-label:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.terms-container {
  width: 100%;
  margin: 10px 0 20px;
}

.terms-container label {
  color: white;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.terms-container input[type="checkbox"] {
  width: auto;
  margin: 0;
  cursor: pointer;
  accent-color: #FE6B8B;
  transform: scale(1.2);
}

.terms-container a {
  color: #FE6B8B;
  text-decoration: none;
  transition: color 0.3s ease;
}

.terms-container a:hover {
  color: #FF8E53;
  text-decoration: underline;
}

.Auth-form button.text-button {
  background: none;
  border: none;
  color: #FE6B8B;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 10px;
  text-decoration: underline;
  padding: 5px;
}

.Auth-form button.text-button:hover {
  color: #FF8E53;
}

.Auth-form .success-message {
  color: #4caf50;
  font-size: 0.8em;
  margin-bottom: 10px;
  text-align: center;
}

.Auth-form .error-message {
  color: #f44336;
  font-size: 0.8em;
  margin-bottom: 10px;
  text-align: center;
}

