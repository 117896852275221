.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(to bottom right, #1e1e1e, #2d2d2d);
}

.upload-form {
  width: 90%;
  max-width: 800px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upload-form h2 {
  color: white;
  margin-bottom: 20px;
  text-align: center;
}

.upload-form input[type="text"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 16px;
}

.upload-form input[type="text"]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.upload-form button[type="submit"] {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(45deg, #c084fc, #db2777);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s;
}

.upload-form button[type="submit"]:hover {
  transform: translateY(-2px);
}

.input-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.input-group > * {
  flex: 1;
}

.input-group input::placeholder {
  color: rgba(255, 255, 255, 0.7);
} 